import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import helpers from './helpers/helper.js';
import endpoints from './services/endpoints.js';
import 'element-plus/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.css'; 
import './assets/css/style.css';
import './assets/css/main.css';
import './assets/css/themify-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icomoon.css';
import './assets/css/fontawesome-all.css';

const app = createApp(App);


app.use(store)
  .use(router)
  .use(ElementPlus)

  app.config.globalProperties.$helpers=helpers;
  app.config.globalProperties.$endpoints=endpoints;

app.mount('#app');
