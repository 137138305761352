import axios from 'axios';
import helpers from '@/helpers/helper.js';
import router from '@/router';


const API_URL = helpers.API_URL;
const BASE_URL = helpers.API_ENDPOINT;

class apiService {
  getBlogs(page = 1, perPage = 15, query = '') {
    return axios
      .get(`${API_URL}public/blog-posts`, {
        params: {
          page,
          per_page: perPage,
          search: query
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          return {
            blogs: response.data.data,
            meta: {
              current_page: response.data.meta.current_page,
              total: response.data.meta.total,
            },
          };
        } else {
          throw new Error('Invalid response data.');
        }
      })
      .catch((error) => {
        console.error('Error fetching blogs:', error);
        throw error;
      });
  }

  // getBlogs(page = 1, perPage = 15) {
  //   return axios
  //     .get(`${API_URL}public/blog-posts`, {
  //       params: {
  //         page,
  //         per_page: perPage,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data && response.data.data) {
  //         return {
  //           blogs: response.data.data,
  //           meta: {
  //             current_page: response.data.meta.current_page,
  //             total: response.data.meta.total,
  //           },
  //         };
  //       } else {
  //         throw new Error('Invalid response data.');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching blogs:', error);
  //       throw error;
  //     });
  // }
  

  getAllPartners() {
    return axios
      .get(API_URL + "partner-profiles")
      .then((response) => {
        console.log(response.data);
        return response.data; 
      })
      .catch((error) => {
        console.error('Error fetching partners:', error);
        throw error;
      });
  }
  getPartnerProfiles(partnerId){
    return axios
    .get(API_URL + "partner-profiles/" + partnerId)
      .then((response) => {
        console.log(response.data);
        return response.data; 
      })
      .catch((error) => {
        console.error('Error fetching partners:', error);
        throw error;
      });
  }
  submitForm(data) {
    return axios
    .post(API_URL+ "partner-profiles", data);
  }
  fetchPartner(partnerId) {
    return axios
  .get(API_URL + "partner-profiles/" + partnerId)
  }
  submitForm1(data) {
    return axios
    .post(API_URL+ "partners-clients", data);
  }
  
  
}

export default new apiService();
