import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: 'home',
    
    
    component: () => import('../views/Navbar.vue'),
   
    meta: {
      title: 'Dashboard',
      authorize:[]
    },
    children: [
     
      
      {
        path: '/about',
        name: 'about',
        components: {
          default: () => import(/* webpackChunkName: "dashboard" */ '../views/About.vue'),
          
        }
      },
      
      
      
      {
        path: '/contact/:type',
        name: 'contact',
        components: {
          default: () => import(/* webpackChunkName: "dashboard" */ '../views/Contact.vue'),
          
        }
      },
      {
        path: '/',
        name: 'home',
        components: {
          default: () => import(/* webpackChunkName: "dashboard" */ '../views/Index.vue'),
          
          
        }
      },
       
    ]
   
  },
 
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;

