export const API_URL = "https://icpapi.zemocard.com/api/";
// export const API_URL = "https://icptst.zemocard.com/api/";
// export const API_URL = "http://127.0.0.1/new/zemo-ideal-customer-platform/api/public/api/";

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("en", { day: "numeric" });
}
function formatMonth(dateString) { 
  const date = new Date(dateString);
  return date.toLocaleString("en", { month: "short" });
}
function dateFormat(date) {
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return formattedDate;
}
function highlightText(text) {
  if (this.searchQuery) {
    const query = this.searchQuery.trim();
    const regex = new RegExp(query, "gi");
    const highlightedText = text.replace(
      regex,
      `<span class="highlight">$&</span>`,
    );
    return highlightedText.replace(
      /<span class="highlight">([^<]+)<\/span>/g,
      '<span class="highlight">$1</span>',
    );
  }
  return text;
}




export default {
  // API_ENDPOINT,
  API_URL,
  formatDate,
  formatMonth,
  dateFormat,
  highlightText,
};
